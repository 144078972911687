import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import VideoPreview from "components/ui/base/VideoPreview";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FullWidthImage from "components/ui/base/FullWidthImage";
import MobileBox from "components/ui/extended/MobileBox";
import FeatureGrid from "components/ui/base/FeatureGrid";
import VideoLiveChatBox from "components/ui/extended/VideoLiveChatBox";
import InstallLiveChat from "components/ui/extended/InstallLiveChat";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import PlainHeader from "../../components/ui/base/PlainHeader";

interface LiveChatPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: LiveChatPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.live-chat.seo.title")}
        description={t("pages.features.live-chat.seo.description")}
        image={{
          relativePath: "meta/customerly-live-chat-with-superpower.jpg",
          alt: "Live Chat with Superpowers",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.features.live-chat.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.features.live-chat.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.features.live-chat.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.features.live-chat.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.features.live-chat.title")}
        description={t("pages.features.live-chat.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={650}
        descriptionMaxWidth={600}
        image={{
          relativePath:
            "pages/features/live-chat/live-chat-with-superpowers-from-customerly.jpg",
          alt: "Live Chat with Superpowers by Customerly",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Live Chat",
            path: "/features/live-chat/",
          },
        ]}
      />

      <VideoLiveChatBox />

      <VideoPreview
        id={"chat-previews"}
        title={t("pages.features.live-chat.1.title")}
        paragraph={t("pages.features.live-chat.1.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath:
              "pages/features/live-chat/live-chat-typing-previews.mov",
          },
        ]}
      />

      <HorizontalStoriesMenu
        id={"canned-responses"}
        title={t("pages.features.live-chat.2.title")}
        paragraph={t("pages.features.live-chat.2.subtitle")}
        storiesPosition={"right"}
        elements={[
          {
            title: t("pages.features.live-chat.2.elements.1.title"),
            description: t("pages.features.live-chat.2.elements.1.description"),
            story: {
              relativePath: "pages/features/live-chat/canned-responses.mov",
            },
          },
          {
            title: t("pages.features.live-chat.2.elements.2.title"),
            description: t("pages.features.live-chat.2.elements.2.description"),
            story: {
              relativePath:
                "pages/features/live-chat/canned-response-replace-property.mov",
            },
          },
        ]}
      />

      <ImageWithDescription
        id={"rich-messages"}
        title={t("pages.features.live-chat.3.title")}
        description={t("pages.features.live-chat.3.subtitle")}
        image={{
          relativePath:
            "pages/features/live-chat/live-chat-reply-text-richtext.jpg",
          alt: t("pages.features.live-chat.3.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"file-sharing"}
        title={t("pages.features.live-chat.4.title")}
        description={t("pages.features.live-chat.4.subtitle")}
        image={{
          relativePath:
            "pages/features/live-chat/live-chat-send-attachments-pdf-png-audio.jpg",
          alt: t("pages.features.live-chat.4.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        id={"chat-archives"}
        title={t("pages.features.live-chat.5.title")}
        description={t("pages.features.live-chat.5.subtitle")}
        image={{
          relativePath: "pages/features/live-chat/live-chat-inboxes.jpg",
          alt: t("pages.features.live-chat.5.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <FullWidthImage
        title={t("pages.features.live-chat.6.title")}
        description={t("pages.features.live-chat.6.subtitle")}
        descriptionMaxWidth={700}
        cta={{
          link: "/features/conversation-inbox/",
          text: t("pages.features.live-chat.6.cta"),
        }}
        image={{
          relativePath:
            "pages/features/live-chat/live-chat-dashboard-inbox-backend-for-agents.jpg",
          alt: t("pages.features.live-chat.6.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.features.live-chat.7.title")}
        description={t("pages.features.live-chat.7.subtitle")}
        cta={{
          link: "/features/help-center/",
          text: t("pages.features.live-chat.7.cta"),
        }}
        image={{
          relativePath:
            "pages/features/live-chat/live-chat-automated-questions-pre-chat-surveys.jpg",
          alt: t("pages.features.live-chat.7.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.live-chat.8.title")}
        description={t("pages.features.live-chat.8.subtitle")}
        cta={{
          link: "/features/live-chat-api/",
          text: t("pages.features.live-chat.8.cta"),
        }}
        image={{
          relativePath:
            "pages/features/live-chat/live-chat-messenger-welcome-message-recognize-users.jpg",
          alt: t("pages.features.live-chat.8.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.live-chat.9.title")}
        description={t("pages.features.live-chat.9.subtitle")}
        image={{
          relativePath:
            "pages/features/live-chat/live-chat-automated-questions-pre-chat-surveys.jpg",
          alt: t("pages.features.live-chat.9.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.live-chat.10.title")}
        description={t("pages.features.live-chat.10.subtitle")}
        image={{
          relativePath:
            "pages/features/live-chat/live-chat-notifications-new-chat.jpg",
          alt: t("pages.features.live-chat.10.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.live-chat.11.title")}
        description={t("pages.features.live-chat.11.subtitle")}
        cta={{
          link: "/features/smart-assignment-rules/",
          text: t("pages.features.live-chat.11.cta"),
        }}
        image={{
          relativePath: "pages/features/live-chat/live-chat-smart-rules.jpg",
          alt: t("pages.features.live-chat.11.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <MobileBox />

      <FeatureGrid
        title={t("pages.features.live-chat.features.title")}
        sections={[
          {
            title: t("pages.features.live-chat.features.helpCenter.title"),
            description: t(
              "pages.features.live-chat.features.helpCenter.description"
            ),
            image: {
              alt: t("pages.features.live-chat.features.helpCenter.title"),
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t(
                  "pages.features.live-chat.features.helpCenter.links.helpCenter"
                ),
                path: "/features/help-center/",
              },
              {
                text: t(
                  "pages.features.live-chat.features.helpCenter.links.autoReply"
                ),
                path: "/features/help-center/#chat-auto-reply",
              },
              {
                text: t(
                  "pages.features.live-chat.features.helpCenter.links.inChatSearch"
                ),
                path: "/features/help-center/#inchat-search",
              },
              {
                text: t(
                  "pages.features.live-chat.features.helpCenter.links.articleEditor"
                ),
                path: "/features/help-center/#article-editor",
              },
              {
                text: t(
                  "pages.features.live-chat.features.helpCenter.links.articleStats"
                ),
                path: "/features/help-center/#article-stats",
              },
              {
                text: t(
                  "pages.features.live-chat.features.helpCenter.links.googleTagManager"
                ),
                path: "/features/help-center/#google-tag-manager",
              },
              {
                text: t(
                  "pages.features.live-chat.features.helpCenter.links.articleFeedback"
                ),
                path: "/features/help-center/#article-feedback",
              },
            ],
          },
          {
            title: t(
              "pages.features.live-chat.features.customerEngagement.title"
            ),
            description: t(
              "pages.features.live-chat.features.customerEngagement.description"
            ),
            image: {
              alt: t(
                "pages.features.live-chat.features.customerEngagement.title"
              ),
              relativePath: "icons/live-chat-customer-engagement.png",
            },
            links: [
              {
                text: t(
                  "pages.features.live-chat.features.customerEngagement.links.engagement"
                ),
                path: "/features/live-chat-engagement/",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customerEngagement.links.welcomeMessage"
                ),
                path: "/features/live-chat-engagement/#welcome-message",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customerEngagement.links.preChatSurveys"
                ),
                path: "/features/live-chat-engagement/#pre-chat-survey",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customerEngagement.links.smartAssignmentRules"
                ),
                path: "/features/smart-assignment-rules/",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customerEngagement.links.bookMeeting"
                ),
                path: "/features/live-chat-engagement/#book-meeting",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customerEngagement.links.helpCenter"
                ),
                path: "/features/live-chat-engagement/#help-center",
              },
            ],
          },
          {
            title: t("pages.features.live-chat.features.customization.title"),
            description: t(
              "pages.features.live-chat.features.customization.description"
            ),
            image: {
              alt: t("pages.features.live-chat.features.customization.title"),
              relativePath: "icons/live-chat-customizations.png",
            },
            links: [
              {
                text: t(
                  "pages.features.live-chat.features.customization.links.customization"
                ),
                path: "/features/live-chat-customization/",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customization.links.brandMatching"
                ),
                path: "/features/live-chat-customization/#brand-matching",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customization.links.chatIcons"
                ),
                path: "/features/live-chat-customization/#chat-icons",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customization.links.agentProfiles"
                ),
                path: "/features/live-chat-customization/#agent-profiles",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customization.links.companyIntro"
                ),
                path: "/features/live-chat-customization/#company-intro",
              },
              {
                text: t(
                  "pages.features.live-chat.features.customization.links.languages"
                ),
                path: "/features/live-chat-customization/#chat-languages",
              },
            ],
          },
          {
            title: t("pages.features.live-chat.features.analytics.title"),
            description: t(
              "pages.features.live-chat.features.analytics.description"
            ),
            image: {
              alt: t("pages.features.live-chat.features.analytics.title"),
              relativePath: "icons/live-chat-team-reports.png",
            },
            links: [
              {
                text: t(
                  "pages.features.live-chat.features.analytics.links.teamReports"
                ),
                path: "/features/team-reports/",
              },
              {
                text: t(
                  "pages.features.live-chat.features.analytics.links.chatReports"
                ),
                path: "/features/team-reports/#chat-reports",
              },
              {
                text: t(
                  "pages.features.live-chat.features.analytics.links.agentReports"
                ),
                path: "/features/team-reports/#agent-reports",
              },
              {
                text: t(
                  "pages.features.live-chat.features.analytics.links.helpCenterReports"
                ),
                path: "/features/team-reports/#help-center-reports",
              },
              {
                text: t(
                  "pages.features.live-chat.features.analytics.links.failedSearchesReports"
                ),
                path: "/features/team-reports/#failed-searches-reports",
              },
            ],
          },
          {
            title: t("pages.features.live-chat.features.nerds.title"),
            description: t(
              "pages.features.live-chat.features.nerds.description"
            ),
            image: {
              alt: t("pages.features.live-chat.features.nerds.title"),
              relativePath: "icons/live-chat-for-developers.png",
            },
            links: [
              {
                text: t("pages.features.live-chat.features.nerds.links.api"),
                path: "/features/live-chat-api/",
              },
              {
                text: t(
                  "pages.features.live-chat.features.nerds.links.callbacks"
                ),
                path: "/features/live-chat-api/#callbacks",
              },
              {
                text: t("pages.features.live-chat.features.nerds.links.events"),
                path: "/features/live-chat-api/#event-tracking",
              },
              {
                text: t(
                  "pages.features.live-chat.features.nerds.links.attributes"
                ),
                path: "/features/live-chat-api/#user-properties",
              },
              {
                text: t(
                  "pages.features.live-chat.features.nerds.links.userAuthentication"
                ),
                path: "/features/live-chat-api/#user-authentication",
              },
            ],
          },
          {
            title: t("pages.features.live-chat.features.mobile.title"),
            description: t(
              "pages.features.live-chat.features.mobile.description"
            ),
            image: {
              alt: t("pages.features.live-chat.features.mobile.title"),
              relativePath: "icons/mobile.png",
            },
            links: [
              {
                text: t(
                  "pages.features.live-chat.features.mobile.links.android"
                ),
                path: "/features/live-chat-for-mobile-apps/",
              },
              {
                text: t("pages.features.live-chat.features.mobile.links.ios"),
                path: "/features/live-chat-for-mobile-apps/",
              },
            ],
          },
        ]}
      />

      <InstallLiveChat />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
      <QuestionsAndAnswers
        title={t("pages.features.live-chat.faq.title")}
        description={t("pages.features.live-chat.faq.description")}
        elements={[1, 2, 3].map((index) => {
          return {
            question: t(`pages.features.live-chat.faq.${index}.question`),
            answer: t(`pages.features.live-chat.faq.${index}.answer`),
          };
        })}
      />
    </GlobalWrapper>
  );
};

export const liveChatQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customerly-live-chat-with-superpower.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
